<template>
    <g>
        <path d="M60,120C26.9,120,0,93.1,0,60C0,26.9,26.9,0,60,0c33.1,0,60,26.9,60,60C120,93.1,93.1,120,60,120z M60,3C28.6,3,3,28.6,3,60s25.6,57,57,57c31.4,0,57-25.6,57-57S91.4,3,60,3z"/>
        <path d="M79.7,37.5H69.4v-1.9c0-3.1-2.5-5.6-5.6-5.6h-7.5c-3.1,0-5.6,2.5-5.6,5.6v1.9H40.3c-2.6,0-4.7,2.1-4.7,4.7v6.6c0,1,0.8,1.9,1.9,1.9h1l1.6,34c0.1,3,2.6,5.4,5.6,5.4h28.5c3,0,5.5-2.4,5.6-5.4l1.6-34h1c1,0,1.9-0.8,1.9-1.9v-6.6C84.4,39.6,82.3,37.5,79.7,37.5 M54.4,35.6c0-1,0.8-1.9,1.9-1.9h7.5c1,0,1.9,0.8,1.9,1.9v1.9H54.4V35.6z M39.4,42.2c0-0.5,0.4-0.9,0.9-0.9h39.4c0.5,0,0.9,0.4,0.9,0.9v4.7H39.4V42.2z M76.1,84.5c0,1-0.9,1.8-1.9,1.8H45.8c-1,0-1.8-0.8-1.9-1.8l-1.6-33.8h35.4L76.1,84.5z"/>
        <path d="M60,82.5c1,0,1.9-0.8,1.9-1.9V56.2c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9v24.4C58.1,81.7,59,82.5,60,82.5" />
        <path d="M69.4,82.5c1,0,1.9-0.8,1.9-1.9V56.2c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9v24.4C67.5,81.7,68.3,82.5,69.4,82.5"/>
        <path d="M50.6,82.5c1,0,1.9-0.8,1.9-1.9V56.2c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9v24.4C48.7,81.7,49.6,82.5,50.6,82.5"/>
    </g>
</template>

<script>
export default{ }
</script>